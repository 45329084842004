import shillong_bg from "../../assets/images/shillong-bg.webp";
import khasi from "../../assets/images/image18.webp";
import jainitia from "../../assets/images/breathtaking-scenery-amazing-landscape-view.webp";
import elephant_falls1 from "../../assets/images/image21.webp";
import silk from "../../assets/images/silk-vill.webp";
import sohra1 from "../../assets/images/image5.webp";
import arrival from "../../assets/images/arrival.webp";
import departure from "../../assets/images/departure.webp";
import boating from "../images/image2.webp";
import smit from "../images/smit.webp";
import mawkdok_bridge1 from "../images/mawkdok.webp";
import mawlynong from "../../assets/images/Mawlynnong-The-cleanest-city-in-Asia-soulveda.webp";
import mawkdok_bridge5 from "../../assets/images/image16.webp";
import mawsmai from "../images/mawsmai.webp";
import dawki1 from "../../assets/images/image38.webp";
import Wei_Sawdong_Falls from "../../assets/images/wei.webp";
import Kudengrim from "../../assets/images/evenezer-marak-xVvc3L5quPU-unsplash.webp";
import garoHills from "../images/garoHillls.webp";
import tura1 from "../../assets/images/image25.webp";
import balpakram1 from "../../assets/images/image5.webp";
import wardLake from "../../assets/images/Ward__Lake.webp";
import nongriat from "../../assets/images/image25.webp";
import donBosco from "../../assets/images/don-bosco-center.webp";
import shillongPeak from "../../assets/images/shillong-peak.webp";
import canyons from "../../assets/images/Laitlum_Canyons.webp";
import golf_course from "../../assets/images/Golf-course.webp";
import doubleBridge from "../images/image23.webp";
import lady_hyadri from "../../assets/images/Meghalaya-Lady-Hydari.webp";
import shillong_market from "../../assets/images/shillong-market.webp";
import local_cuisine from "../../assets/images/shillong-cuisine.webp";
import davi_scott from "../../assets/images/davi-scott.webp";
import assam from "../../assets/images/ashwina-kumar-MZS-QFeeVPc-unsplash.webp";
import local_festival from "../../assets/images/local-festival.webp";
import couple_trip from "../../assets/images/couple-trip.webp";
import business_trip from "../../assets/images/business-trip.webp";
import family_trip from "../../assets/images/family-trip.webp";
import friends_trip from "../../assets/images/friends-trip.webp";
import umiam_lake from "../images/umiam-lake.webp";
import wah_kba from "../images/image34.webp";
import Garden_of_Caves from "../images/image37.webp";
import Lyngksiar_Falls from "../images/image11.webp";
import mawkma_falls from "../images/image3.webp";
import prut_falls from "../images/image9.webp";
import nohkalikai from "../images/chirayu-sharma-KAXnXl_S6K4-unsplash.webp";
import krang_suri from "../images/mawlynnong-new.webp";
import khasi_cuisine from "../images/khasi-food.webp";
import khasi_tradition from "../images/khasi-tradition.webp";
import nartiang from "../images/Nartiang_Monoliths_in_Meghalaya_are_tallest_in_the_world.webp";
import Nartiang_durga_temple from "../images/Nartiang_Durga_temple.webp";
import wahrilang_view_point from "../images/Wah.webp";
import nongnah_view_point from "../images/Nongnah.webp";
import mawtynhiang_caves from "../images/Mawtynhiang.webp";
import chum_chum_falls from "../images/Chum.webp";
import od_ringai_falls from "../images/od.webp";
import Nongkhnum from "../images/Nongkhnum.webp";
import Noh from "../images/chum-chum-content.webp";
import mawsy from "../images/mawsy.webp";
import krang from "../images/Krang.webp";
import Tyrshi from "../images/Tyrshi.webp";
import Syntu from "../images/Syntu.webp";
import caves from "../images/caves.webp";
import Thadlaskein from "../images/Thadlaskein.webp";
import phe_phe from "../images/phe-phe.webp";
import Kaziranga from "../images/Kaziranga-National-Park.webp";
import kamakhya_temple from "../images/Kamakhya-Temple-full-color-edited.webp";
import Behdeinkhlam from "../images/Behdeinkhlam.webp";
import tea from "../images/tea.webp";
import seven_sisters from "../images/seven-sisters.webp";
import manas from "../images/manas.webp";
import assam_zoo from "../images/assam-zoo.webp";
import river_brahma from "../images/river-bramha.webp";
import bihu from "../images/bihu.webp";
import Assamese_Cuisine from "../images/assam-cuisine.webp";
import garo_cuisine from "../images/garo_cuisine.webp";
import jadesii from "../images/Jadesil-Fish-Sanctuary.webp";
import nengman from "../images/Nengmandalgre-mahseers.webp";
import nonkred from "../images/nonkrekad.webp";
import siju from "../images/siju-cave.webp";
import wari from "../images/Wari-chora.webp";
import rongren from "../images/Meghalaya2.webp";
import garo_culture from "../images/garo-culture.webp";
import beadon from "../images/beadon-falls.webp";
import arwah from "../images/arwah.webp";
import sweet_falls from "../images/sweet-falls.webp";
import kynrem from "../images/kynrem.webp";
import dainthlen from "../images/Dain-Thlen.webp";
import mawmluh from "../images/Mawmluh.webp";
import thangkharang from "../images/Thangkharang.webp";
import ecopark from "../images/EcoPark.webp";
import shillong1 from "../images/shillong-itinerary-1.webp";
import shillong2 from "../images/shillong-itinerary-2.webp";
import shillong3 from "../images/shillong-itinerary-3.webp";
import shillong4 from "../images/shillong-itinerary-4.webp";
import shillong5 from "../images/shillong-itinerary-5.webp";
import shillong6 from "../images/shillong-itinerary-6.webp";
import shillong7 from "../images/shillong-itinerary-7.webp";
import shillong8 from "../images/shillong-itinerary-8.webp";
import shillong9 from "../images/shillong-itinerary-9.webp";
import shillong10 from "../images/shillong-itinerary-10.webp";
import shillong11 from "../images/shillong-itinerary-11.webp";
import shillong12 from "../images/shillong-itinerary-12.webp";
import shillong13 from "../images/shillong-itinerary-13.webp";
import shillong14 from "../images/shillong-itinerary-14.webp";
import shillong15 from "../images/shillong-itinerary-15.webp";
import shillong16 from "../images/shillong-itinerary-16.webp";
import shillong17 from "../images/shillong-itinerary-17.webp";
import shillong18 from "../images/shillong-itinerary-18.webp";
import shillong19 from "../images/shillong-itinerary-19.webp";
import shillong20 from "../images/shillong-itinerary-20.webp";
import shillong21 from "../images/shillong-itinerary-21.webp";
import mawlynong1 from "../images/mawlynnong-itinerary.webp";
import mawlynong2 from "../images/mawlynnong-itinerary-1.webp";
import mawlynong3 from "../images/mawlynnong-itinerary-2.webp";
import mawlynong4 from "../images/mawlynnong-itinerary-3.webp";
import nongriat1 from "../images/nongriat-itinerary.webp";
import nongriat2 from "../images/nongriat-itinerary-1.webp";
import nongriat3 from "../images/image23.webp";
import mawsynram1 from "../images/mawsynram-itinerary.webp";
import mawsynram2 from "../images/mawsynram-itinerary-1.webp";
import mawsynram3 from "../images/mawsynram-itinerary-2.webp";
import jainitia1 from "../images/jaintia-itinerary.webp";
import jainitia2 from "../images/jaintia-itinerary-1.webp";
import garo1 from "../images/garo-itinerary.webp";
import garo2 from "../images/garo-itinerary-1.webp";
import garo3 from "../images/garo-itinerary-2.webp";
import garo4 from "../images/garo-itinerary-3.webp";
import cathedral from "../images/cathedral.webp";
import krangsuri from "../images/krangsuri.webp";
import chympe from "../images/chympe.webp";
import wei_saw from "../images/wei-saw.webp";

export const navLinks = {
  locations: [
    {
      main_heading: "Shillong",
      main_link: "/Shillong",
    },
    {
      main_heading: "Sohra",
      main_link: "/Sohra",
    },

    {
      main_heading: "Jaintia Hills",
      main_link: "/Jaintia Hills",
    },
    {
      main_heading: "South West Khasi Hills",
      main_link: "/South West Khasi Hills",
    },
    {
      main_heading: "Garo Hills",
      main_link: "/Garo Hills",
    },
    {
      main_heading: "Assam",
      main_link: "/Assam",
    },
  ],

  Packages: [
    {
      package_name: "Family Trip",
      package_link: "/tour-package/FAMILY TRIP",
    },
    {
      package_name: "Couple Trip",
      package_link: "/tour-package/COUPLE TRIP",
    },
    {
      package_name: "Friends Trip",
      package_link: "/tour-package/FRIENDS TRIP",
    },
    {
      package_name: "Corporate Trip",
      package_link: "/tour-package/BUSINESS TRIP",
    },
  ],
};

export const locationData = {
  mainLocations: [
    {
      name: "Shillong",
      description:
        "The Shillong journey package offers a charming tour to the adorable city of Shillong, which is located some of the lush Meghalayan hills. Shillong, additionally known as the 'Scotland of the East', enchants tourists with its lovely surroundings, agreeable weather, and lively way of life. With this excursion package deal, you could discover Shillong's pinnacle points of interest, consisting of as the well-known Elephant Falls, the tranquil Ward's Lake, the beautiful Shillong Peak, and the bustling marketplaces wherein you could buy neighborhood specialties and handicrafts. The Shillong tour package ensures a memorable revel in so that it will enthrall you, whether you're a fan of nature, an adventurer, or a tradition vulture.",
      image: shillong_bg,
      facts: [
        "Shillong is the capital and largest city of the Indian state of Meghalaya.",
        "It is known as the 'Scotland of the East' due to its scenic beauty and pleasant climate.",
        "Shillong Peak, located at an altitude of 1,965 meters, offers stunning views of the city.",
        "The famous Elephant Falls, a three-tiered waterfall, is a popular tourist attraction.",
        "Shillong is also known for its vibrant music and cultural festivals.",
      ],
      sublocations: [
        {
          name: "Don Bosco Museum",
          description:
            "Familiarize yourself with the cultures and traditions of all North Eastern state at just one place. Considered one of the finest museums in Asia promoting indigenous and tribal cultures, Don Bosco Museum hosts 17 galleries spanning across 7 floors that showcase regional attires, ornamentation, weapons, handicrafts and artwork and more.",
          images: donBosco,
        },
        {
          name: "Elephant Falls",
          description:
            "Regarded as one of the most scenic waterfalls not only in Shillong but across the whole of North Eastern India, Elephant Falls is a must-visit when you find yourself in Shillong. The falls have three layers in their formation and hence are also called ‘The Three Step Waterfalls’.",
          images: elephant_falls1,
        },
        {
          name: "Golf Course",
          description:
            "Adorned by forestation of thick pine and rhododendron trees, the Shillong Golf Course has been titled the ‘Gleneagle of the East’ by the United States Golf Association and Museum for its greenery and beauty. It is one of the oldest, finest and largest natural golf circuits in the world. A 9-hole golf course established in 1898, it was converted to an 18-hole course in 1924.",
          images: golf_course,
        },
        {
          name: "Shillong Peak",
          description:
            "Shillong Peak, one of the most important and mesmerising places in Shillong is the highest point in all of Meghalaya. It uplifts anyone who views the panoramic sights it offers. Tourists can treat their eyes to views of the Himalayas, the Bangladesh Plains and nearby waterfalls all in one place. ",
          images: shillongPeak,
        },
        {
          name: "Police Bazar",
          description:
            "The most famous shopping destination for both locals and tourists in Shillong, the Police Bazar is a market that has it all - from traditional handicraft stores, merchandise, and regional clothing to hotels and eating joints. The Police Bazar is also known as Khyndailad and is one of the best places to book a hotel while travelling to Shillong. Street shopping and food stalls are the highlights of the bazaar. ",
          images: shillong_market,
        },
        {
          name: "Umiam Lake",
          description:
            "One of the major attractions of Meghalaya, Shillong's Umiam Lake is also known as the Barapani Lake and Umiam literally means 'Water of tears'. It is bounded by the scenic Sylvan hills on all sides making it an unforgettable sightseeing experience..",
          images: umiam_lake,
        },
        {
          name: "Bishop And Beadon Falls",
          description:
            "Located at Shillong's Suna Valley, the Beadon-Bishop Falls is a sight to cherish. These twin falls are considered to be Meghalaya's most beautiful waterfalls. The highest point of these falls is at 135 m. This is a popular picnic spot for tourists. The falling water from the top of the hills is said to look like a white ribbon",
          images: beadon,
        },

        {
          name: "Umden Silk Weaving Village",
          description:
            "The Silk-Weaving Village of Umden-Diwon is the world's first Eri Silk village. It is located in the Ri-Bhoi district of Meghalaya. Popularly known as the Ahimsa Silk, the silk at Umden-Diwon is processed from cocoons without killing the larvae inside.Tourists will get to experience the rich textile heritage and culture of the village along with the diverse surroundings and variety of attractions. ",
          images: silk,
        },
        {
          name: "Mawphlang David Scott Trail",
          description:
            "The David Scott Trail is one of the oldest trails of Meghalaya, which passes through the Eastern Khasi Hills and takes its course through two popular villages of Khasi, Mawphlang and Lad Mawphlang. It is a 16 km trek through the picturesque valleys, majestic mountains, lush green forests, and the scenic Umiam Rive",
          images: davi_scott,
        },
        {
          name: "Smit Village",
          description:
            "Smit Village is popularly known as the cultural centre of the Khasi Hills. It is located in the Mawryngkneng block of the East Khasi Hills district and is the largest village in the block. This calm and serene village is the best spot for travellers to have an experience of rural life. Smit offers an otherworldy experience to those who visit it",
          images: smit,
        },
        {
          name: "Sweet Falls",
          description:
            "Sweet Falls of Shilong, also called Kshaid Weitden, is a waterfall that is considered the most spectacular waterfall in Shillong. However, despite its name, this waterfall is said to be the most dangerous waterfall in Shillong owing to poorly maintained routes and slippery surfaces. The waterfall has a tall height of 96 m making it impossible to swim in the waters.",
          images: sweet_falls,
        },
        {
          name: "Cathedral of Mary Help of Christians",
          description:
            "The Cathedral of Mary Help of Christians is also known as Laitumkhrah Church and stands on the site where the very first church of Shillong was built. It is named after the mother of Jesus, Mary. Tourists are delighted at the sight of this cathedral with its architectural elegance and pristine scriptures. The Church has tall arches and huge stained-glass windows.",
          images: cathedral,
        },
      ],
      thingsToDo: [
        {
          name: "Ward's Lake",
          image: wardLake,
          description:
            "Take a leisurely stroll around Ward's Lake, a lovely man-made lake in the center of Shillong. Enjoy boating, picnics, or simply relaxing in the tranquil surroundings.",
        },
        {
          name: "Don Bosco Museum",
          image: donBosco,
          description:
            "TDon Bosco Centre for Indigenous Cultures: You can gain insight into our rich cultural heritage of our Northeast region at this Impressive Centre. It has galleries and exhibitions showcasing magnificent arts, crafts, fashion and traditions          ",
        },
        {
          name: "Shillong Peak",
          image: shillongPeak,
          description:
            "Visit Shillong Peak, the highest point in Shillong, offers panoramic views of the city, Himalayas, lush green surroundings, and Bangladesh plains at 1962 m above sea level.",
        },
        {
          name: "Laitlum Canyons",
          image: canyons,
          description:
            "Explore the Laitlum Canyons, a beautiful hilltop in the 'Smit Valley', offers breathtaking views of Shillong and the end of hills, known as Laitlum. The canyon is known for its white misty blanket and breathtaking landscapes.",
        },
        {
          name: "Shillong's Golf Course",
          image: golf_course,
          description:
            "Shillong's golf course, known as the Glen Eagles of the East, is a beautiful natural course with open meadows and pine groves. Visitors enjoy strolling, crossing bridges, enjoying street food, and marveling at the monoliths.",
        },
        {
          name: "Lady Hydari Park",
          image: lady_hyadri,
          description:
            "Visit Lady Hydari Park, Shillong city's Phan Nonglait Park, formerly Lady Hydari Park, offers a popular outdoor recreational area with flower beds, lawns, and a pelican lake for a pleasant family getaway.",
        },
        {
          name: "Explore the Local Markets",
          image: shillong_market,
          description:
            "Police Bazaar is a one-stop shop offering hotels, restaurants, malls, and shops for locals and tourists, offering exquisite handicrafts and indigenous ornaments for home-made gifts.",
        },

        {
          name: "Taste Local Cuisine",
          image: local_cuisine,
          description:
            "Taste Local Cuisine: Jadoh, a popular dish in Meghalaya, is made with red rice and pork meat, often cooked with chicken or fish. A mix of green chilies, onions, ginger, turmeric, black pepper, and bay leaves is used, with turmeric giving the rice its rich yellow color and aromatic flavor.",
        },
        {
          name: "Trek to David Scott Trail",
          image: davi_scott,
          description:
            "The David Scott Trail, a hiking trail in Meghalaya Highlands, offers an awe-inspiring experience through the subtropical rainforest and pristine forests. Known for its striking views and natural sights, it is a popular destination for adventure seekers.",
        },
        {
          name: "Enjoy Local Festivals",
          image: local_festival,
          description:
            "The Khasi people, an indigenous ethnic group in Meghalaya, form the majority of the eastern part of the state, accounting for 48% of the population. They follow a matrilineal system of descent and inheritance and are granted Scheduled Tribe status under the Indian Constitution.",
        },
        {
          name: "Boating And View Point",
          description:
            "Take to the tranquil waters and enjoy boating on Shillong's pristine lakes. Feel the serenity of the surroundings as you glide across the water, surrounded by lush greenery and the gentle whispers of nature. Whether you're seeking a romantic escape or a peaceful moment of solitude, boating offers an enchanting way to connect with the natural wonders of Shillong.",
          image: boating,
        },
      ],
    },
    {
      name: "Sohra",
      description:
        "Khasi Hills, a part of the Meghalaya plateau, is a mesmerizing region blessed with lush greenery, cascading waterfalls, and captivating valleys. This area is predominantly inhabited by the Khasi tribe, known for their unique customs and traditions. The town of Sohra, formerly known as Sohra, is famous for being one of the wettest places on Earth and offers breathtaking vistas of verdant landscapes and cascading waterfalls. Visitors to Khasi Hills can explore attractions like Mawkdok Bridge Viewpoint, Wah Kba Falls, and the Garden of Caves, immersing themselves in the natural beauty and cultural richness of the region.",
      image: seven_sisters,
      facts: [
        "Sohra, also known as Sohra, is famous for being one of the wettest places on Earth.",
        "It holds the record for the highest annual rainfall, making it a lush and green region.",
        "The Seven Sisters Falls and Nohkalikai Falls are among the most spectacular waterfalls in Sohra.",
        "The Mawsmai Caves, formed from limestone, attract tourists for their unique formations.",
        "Sohra's living root bridges, created by weaving tree roots, are engineering marvels.",
      ],
      sublocations: [
        {
          name: "Mawkdok Dympep Valley",
          description:
            "On the Shillong - Sohra road in Meghalaya, the Mawkdok-Dympep Valley Viewpoint is an alluring valley to behold. It is also called Duwan Sing Syiem Bridge Viewpoint and is one of the major destinations to visit in Sohra. ",
          images: mawkdok_bridge1,
        },
        {
          name: "Wah Kba Falls",
          description:
            "Wah-Kba Falls is a seasonal waterfall located in Sohra. It is one of the most popular waterfalls in Sohra. It is located on the Shillong-Sohra road. This waterfall's roar is audible from a distance and descends from a cliff into a deep gorge. It is a treat to the eyes. The water of the falls is crystal clear in nature. Tourists are amazed at the views of the gushing waters surrounded by beautiful green hills.",
          images: wah_kba,
        },
        {
          name: "Garden of Caves",
          description:
            "The Garden of Caves is a natural cave system in the Jaintia Hills. One's trip to Sohra is incomplete without this hidden gem that Meghalaya has nestled. It is also called Ka Bri Ki Synrang. This heavenly cave system comprises rock formations, stalagmites, stalactites, waterfalls, and narrow streams",
          images: Garden_of_Caves,
        },
        {
          name: "Mawkma Falls",
          description:
            "Mawkma Falls, nestled in the lush landscapes of Meghalaya, India, is a captivating natural wonder that enchants all who visit. Plunging from a considerable height, the falls cascade down in multiple tiers, creating a mesmerizing spectacle of frothy white water against the backdrop of dense greenery. The serene ambiance surrounding Mawkma Falls is heightened by the melodious symphony of nature, making it a serene escape for nature enthusiasts and adventure seekers alike.",
          images: mawkma_falls,
        },
        {
          name: "Lyngksiar Falls",
          description:
            "Located near the Mawkma Village in Sohra, Lyngkshiar Falls is considered to be the most beautiful in the region. It gets its name from the rare orchid called Tiew Lyngkshiar.The journey to the Falls is a short trip on its own as tourists pass amid green forests and picturesque villages. ",
          images: Lyngksiar_Falls,
        },
        {
          name: "Double Decker Living Root Bridge",
          description:
            "Umshiang Double-Decker Root Bridge in Meghalaya is one of nature and man's many amazing co-creations. This living root bridge is crafted by the people of the Khasi tribe. The most thrilling part about the bridge is that it is 3 km long and stands at a height of 2400 feet! The Umshiang River flows beneath the bridge. This bridge is made up of two root bridges stacked one on the other. These roots are of the ancient rubber trees that are indigenous to the Northeast region.",
          images: doubleBridge,
        },
        {
          name: "Prut Falls",
          description:
            "The Prut Falls is situated at the Laitlyndop village in Sohra. The falls have gained popularity since they were inaugurated and are steadily becoming a tourist hub.Tourists will love to visit this waterfall as it offers an opportunity to view it from behind the falls, unlike most other waterfalls.",
          images: prut_falls,
        },
        {
          name: "Wei Sawdong Falls",
          description:
            "Wei Sawdong Falls seems straight out of a wallpaper. That is how spectacular this waterfall is. Nearby, situated is Dainthlen Falls. These two falls are visited together by tourists. Wei Sawdong Falls is a 3 tiered waterfall and is especially known for its bluish-green, crystal-clear water.",
          images: wei_saw,
        },
        {
          name: "Nohkalikai Falls",
          description:
            "Nohkalikai Falls is the tallest plunge waterfall in India. It is a popular tourist destination in Cherrapunji. Travellers visiting the waterfall are impressed by the formation of the falls, the surroundings, and the panoramic views of the waterfall.  The water from the falls gathers at the base in a pool-like formation which is very pleasing to the eyes. Also, the water at the base has a greenish shade. The best time to visit this waterfall is in the monsoons.  ",
          images: nohkalikai,
        },
        {
          name: "Arwah Cave",
          description:
            "Arwah Caves is the perfect offbeat destination for travellers. The Arwah Caves are way more mesmerising than travellers think. They are located in the Khliehshnong area of Cherrapunji. The caves are known for their limestone formations and fossils which are considered to be millions of years old. Certain sections of the caves host stalagmites and stalactites, and there also flows a stream in the caves. The walk to the entrance of the caves is also an experience of its own.  ",
          images: arwah,
        },
        {
          name: "Mawsmai Cave",
          description:
            "One of the most historically popular caves in Meghalaya and also one of the longest, the Mawsmai Cave is a splendid experience for travellers. It is located in Cherrapunji. The cave might be long but the cave route accessible to tourists is short and a very interesting experience. Mawsmai Cave is a limestone cave and is also known for the fossils that can be seen on the walls of the cave as travellers explore the cave and its formations.",
          images: mawsmai,
        },
        {
          name: "Kynrem Falls",
          description:
            "Kynrem Falls is the seventh-highest waterfall in India. It is located inside Thangkharang Park in Cherrapunji.This is a three-tiered waterfall and the tourist are impressed by the loud roar of this waterfall. At the top, Kynrem Falls start in two streams and later, merge into one single water flow in the third tier of the waterfall.The best time to visit Kynrem Falls is monsoon. ",
          images: kynrem,
        },
        {
          name: "Mawmluh Cave",
          description:
            "The Mawmluh Cave, locally known as Krem Mawmluh, has been listed as UNESCO’s one of the ‘First 100 IUGS (International Union of Geological Sciences) Geological Sites’ in the world.It is said to be the fourth longest cave in the Indian subcontinent. The most interesting feature of the cave is the pool that has formed inside due to five different rivers making their way through the cave. ",
          images: mawmluh,
        },
        {
          name: "Dainthlen Falls",
          description:
            " Dainthlen Falls seems straight out of a wallpaper. That is how spectacular this waterfall is. Nearby, situated is Dainthlen Falls. These two falls are visited together by tourists.Dainthlen Falls is a 3 tiered waterfall and is especially known for its bluish-green, crystal-clear water. Dainthlen Falls, on the other hand, is best known for its gorgeous vista. ",
          images: dainthlen,
        },
        {
          name: "Thangkharang Park",
          description:
            "The Thangkharang Park in Cherrapunji acts as a great relaxation point and picnic spot for tourists and locals alike. It is spread over 100 acres and hosts an array of trees and plant species. The park has an eye-catching fountain, a greenhouse, and two amazing viewpoints that offer excellent views of the Khor Ramhah and the valley below. The park has two footbridges which also offer good views of the surroundings.",
          images: thangkharang,
        },
        {
          name: "Eco Park",
          description:
            "The Mawsmai Nongthymmai Eco Park is the perfect spot for a picnic. The chilly and misty atmosphere of the park adds to the overall experience of the park. Tourists have a lot of things they can do at the Eco Park. There is a children's play area, boating, swimming, monoliths, footbridges, and many shelters as well. The Southern corner of Eco Park is the originating point of the Seven Sister Falls",
          images: ecopark,
        },
      ],
      thingsToDo: [
        {
          name: "Visit Seven Sisters' Falls",
          image: seven_sisters,
          description:
            "Take in the breathtaking Seven Sisters' Falls, an outstanding cascade of 7 interwoven waterfalls.",
        },
        {
          name: "Visit Nohkalikai Falls",
          image: nohkalikai,
          description:
            "Visit India's highest plunge waterfall, with a lovely view and picturesque landscape.",
        },

        {
          name: "Visit Mawlynnong Village",
          image: mawlynong,
          description:
            "Visit Asia's cleanest village, with well-kept streets, explore the Living Root Bridge, and have beautiful views from the Sky View Point.",
        },

        {
          name: "Khasi Cuisine",
          image: khasi_cuisine,
          description:
            "Try Traditional Khasi cuisine, which includes fine local specialties such as Jadoh (mixed rice dish), tungrymbai (fermented soybean salad), and many more.",
        },
        {
          name: "Explore Tradition",
          image: khasi_tradition,
          description:
            "Interact with friendly locals to discover their customs, traditions, and folklore. Participate in authentic cultural events, including traditional music and dance performances.",
        },
      ],
    },
    {
      name: "Jaintia hills",
      description:
        "Jaintia Hills, another enchanting region in Meghalaya, is a land of pristine natural beauty and cultural heritage. It is predominantly inhabited by the Jaintia tribe, known for their vibrant festivals and traditional practices. Dawki and Shnongpdeng, situated on the banks of the Umngot River, offer crystal-clear waters that are perfect for boating and mesmerizing views. Krangsuri Falls is another popular attraction, drawing visitors with its pristine pool and scenic surroundings. The Jaintia Hills are a haven for nature lovers and adventure enthusiasts, offering a serene escape from the hustle and bustle of city life.",
      image: jainitia,
      facts: [
        "Jaintia Hills is a district in Meghalaya known for its picturesque landscapes.",
        "It is rich in mineral resources, with coal mining being a significant economic activity.",
        "The region is dotted with numerous natural caves, making it a caving enthusiast's paradise.",
        "The Krang Suri Waterfall, with its turquoise waters, is a hidden gem in Jaintia Hills.",
        "The indigenous culture and festivals of the Jaintia community are vibrant and unique.",
      ],
      sublocations: [
        {
          name: "Dawki",
          description:
            "Remember the crystal clear waters where boats seem floating in the air? These places are Dawki and Shnongpdeng in Meghalaya. One's trip to Meghalaya is truly incomplete without visiting the town of Dawki and the village of Shnongpdeng.Both Dawki and Shnongpdeng are picturesque places situated along the banks of the stunning Dawki (Umngot) River. .",
          images: dawki1,
        },
        {
          name: "Krangsuri Falls",
          description:
            "The glistening blue waters of the Krangsuri Falls are bound to pull tourists to this place. This waterfall is located in Jaintia Hills, Meghalaya. The Krangsuri Falls are a result of the waters of a calm river dropping over a cave. Tourists describe the water pouring down to be like white curtains. One can set up a camp upstream and indulge themselves in the serenity of nature. ",
          images: krangsuri,
        },
        {
          name: "Kudengrim",
          description:
            "Kudengrim is truly a hidden gem situated in the Jaintia Hills of Meghalaya. To reach Kudengrim, travellers take a 20 min hike to the village which is one of the main attractions of the place. One also encounters a beautiful waterfall on the hike. Another marvel to sight here is the Kudengrim Living Root Bridge. ",
          images: Kudengrim,
        },
        {
          name: "Syntu Ksiar",
          description:
            "Syntu Ksiar, meaning 'the golden flower' in the Jaintia language, is an immensely famous picnic spot for tourists and locals. This scenic place is situated on the banks of the Myntdu River. This vast stretch of land is a well-known angling spot. There is a beautiful wire suspension bridge on the river. ",
          images: Syntu,
        },
        {
          name: "Nartiang Monoliths",
          description:
            "Portraying the rich heritage and history of the Jaintia Kingdom, the Nartiang Monoliths are believed to be the tallest monoliths in the world. These monoliths were created as monuments for the kings. This place is as captivating as it is historically rich. The Nartiang Monoliths are also considered to be the largest collection of monoliths in one single place. ",
          images: nartiang,
        },
        {
          name: "Nartiang Durga Temple",
          description:
            "The Nartiang Durga Temple of Jaintia Hills is 600 years old and is a culturally significant place. The Nartiang temple also hosts pieces of war history within its premises like old antique canons. It is one of the 51 Shakti Peethas of Hindu mythology and one of the holiest sites for the Shakti sect of Hinduism",
          images: Nartiang_durga_temple,
        },
        {
          name: "Thadlaskein Lake",
          description:
            "The Thadlaskien Lake is a wonder that is not to be missed. Located near a small village named Mukhla in the West Jaintia Hills, this lake is famous locally by the name of Pung Sajar Nangli.This man-made lake is surrounded by meadows and is a popular hangout spot for locals and tourists alike. A boating facility is also available here which adds to the experience. ",
          images: Thadlaskein,
        },
        {
          name: "Phe Phe Falls",
          description:
            "Welcome to Phe Phe Falls, the literal and scenic paradise of Jaintia Hills, Meghalaya. Phe Phe in the Jaintia language translates to 'Paradise'. The water beautifully descends from a steep hill and tourists can jump in and surrender to nature's therapy in these clear blue waters. Tourists can go kayaking in the river stream near the waterfall. ",
          images: phe_phe,
        },
        {
          name: "Dawki (Umngot) River",
          description:
            "The crystal clear waters of the Dawlki River feel like an unreal wonder! Also known as the Umngot River, it is located in the West Jaintia Hills and is a paradise for those who visit. While boating on the shimmering transparent waters of the Dawki, the boats seem to be floating in the air and also, tourists are able to clearly see the marine life in the river. ",
          images: dawki1,
        },
        {
          name: "Chympe Cave",
          description:
            "The Chympe Cave is a unique river cave formation in the Jaintia Hills. On the journey to the Chympe Cave, the path bifurcates where one leads to the Chympe Waterfall while the other goes to the Cave.Krem Chympe as it is called, Krem meaning 'Cave', is India's 5th longest cave and requires swimming to navigate the course of the cave. The entrance of the cave is a spectacular sight and stunning art of nature. ",
          images: caves,
        },
        {
          name: "Tyrshi falls",
          description:
            "Ttyrshi Falls is one of those treasures of Meghalaya that is lesser-visited but offers much more in terms of beauty. This waterfall is connected by an arched bridge. Tourists can step in and enjoy the cool waters at the base of this picturesque waterfall. This is a place perfect for nature admirers. At the waterfall, tourists can also treat their eyes to the panoramic views of the Pynthor Nein Paddy fields. ",
          images: Tyrshi,
        },
        {
          name: "Chympe Falls",
          description:
            "When we talk about the Chympe Waterfall, it is yet another breathtaking attraction. The way the water pours down the green and rocky terrain, it seems like the roots of a tree. The water that collects below is a shade of emerald and makes the sight unforgettable!",
          images: chympe,
        },
      ],
      thingsToDo: [
        {
          name: "Boating in Dawki River",
          image: dawki1,
          description:
            "Visitors can have a boat ride on the river, which is heavenly with its crystal-clear blue-green water!",
        },
        {
          name: "Tyrshi Falls",
          image: Tyrshi,
          description:
            "Visit Tyrshi Falls, an hour before Krangsuri Falls. Walk through lush fields, small brooks, and a well-maintained path to the waterfalls. View the complete falls from the top, but slippery steps may hinder viewing. Enjoy breathtaking views of the fall.",
        },
        {
          name: "Syntu Ksiar",
          image: Syntu,
          description:
            "Syntu Ksiar is a lovely spot on the Myntdu River in West Jaintia's lower Jowai area. It is a nice picnic spot that is generally less busy and is well worth a visit.",
        },
        {
          name: "Krang Suri Falls",
          image: krang,
          description:
            "Experience the magical waters of the greenish-blue plunge pool and swim up to the rock within the waterfall for breathtaking views. Enjoy overnight camping and upon reaching the top, explore the outstanding greenery and breathtaking views of the waterfall.",
        },
        {
          name: "Trek to Nartiang Monoliths",
          image: nartiang,
          description:
            "Nartiang boasts the largest concentration of monoliths in Meghalaya. These stone monoliths, some dating back to the sixteenth century, are an important part of the Jaintia way of life and history. Take a trek to Nartiang and discover the charming collection of monoliths.",
        },
        {
          name: "Cave Exploration",
          image: caves,
          description:
            "Jaintia Hills has the greatest variety of caves, offering a thrilling adventure for spelunking enthusiasts. Krem Puri, Synrang Pamiang, and Krem Liat Prah are some famous caves that offer cute formations.",
        },
        {
          name: "Thadlaskein Lake",
          image: Thadlaskein,
          description:
            "Surrounded by rolling hills and luxurious greenery, Thadlaskein Lake is a serene spot for picnicking and relaxation. Visitors can enjoy boating at the lake and soak in the pristine ecosystem.",
        },
        {
          name: "Delve into the Local Culture",
          image: Behdeinkhlam,
          description:
            "Immerse yourself in the rich way of life and traditions of the Jaintia people by visiting local villages and interacting with the locals. Explore traditional handicrafts, taste local cuisine, and witness traditional dances and music performances.",
        },
      ],
    },
    {
      name: "Garo Hills",
      description:
        "Garo Hills is known for its diverse wildlife and picturesque landscapes.",
      image: garoHills,
      facts: [
        "Garo Hills is a picturesque region known for its breathtaking landscapes and rich cultural heritage.",
        "The region is inhabited by the Garo tribe, known for their vibrant traditions and unique customs.",
        "Sohra, located in the East Khasi Hills, is one of the wettest places on Earth, receiving heavy rainfall throughout the year.",
        "Tura, the largest town in Garo Hills, is surrounded by lush forests and is a gateway to various natural wonders.",
        "Garo Hills is famous for its traditional musical instruments, intricate handwoven textiles, and skilled artisans.",
      ],
      sublocations: [
        {
          name: "Jadisil Fish Sanctuary",
          description:
            "The Jadisil Fish Sanctuary is placed in Meghalaya's Garo Hills area. It is a main vacationer appeal in addition to a conservation place for quite a few fish species. The Jadisil Fish Sanctuary turned into developed to shield and conserve the sanctuary's fish species even as also selling sustainable tourism within the vicinity.",
          images: jadesii,
        },
        {
          name: "Balpakram National Park",
          description:
            "Garo folklore includes Balpakram, an important part of Garo folklore, a treasure trove of animal and plant life, inspired by paranormal stories and offering expansive views.",
          images: balpakram1,
        },
        {
          name: "Tura Peak",
          description:
            "Tura Peak, located at 900 meters above sea level, is a paradise for nature lovers and bird watching enthusiasts. The diverse landscape, including hornbills and gibbons, offers a moderately difficult hike with stunning vistas of Tura and the surrounding hills. Visitors can also enjoy bird watching and witness the winding Jinjiram River as it flows through the hills.",
          images: tura1,
        },
        {
          name: "Siju Cave and Rock Formations ",
          description:
            "Siju cave, a world-long cave system, features unique visuals and formations, including rock formations and bats. Visitors can explore the state-run tourist lodge and nearby reserves, while bird sanctuary and forests provide a glimpse into the Garo Hills wilderness",
          images: siju,
        },
        {
          name: "Nengmandal Fish Sanctuary",
          description:
            "Nengmandal Fish Sanctuary, located in Chibok river, is a riverine fish sanctuary near Williamnagar town. It is home to major fish species like Chocolate Masheers, minor caps, and eel. Accessible via motorable tarred road in 20 minutes.          ",
          images: nengman,
        },
        {
          name: "Nokrek Biospher Reserve",
          description:
            "Nokrek National Park, situated in the serene Garo Hills of Meghalaya, is a UNESCO Biosphere Reserve and a haven for biodiversity. The park, covering 47.48 sq Km, hosts wildlife species like elephants, tigers, rare cats, Red Panda, clouded leopard, Serow, Giant flying squirrel, and wild buffalo. Visitors can do activities include camping, trekking, bird watching, and observing wild elephants",
          images: nonkred,
        },
        {
          name: "Wari Chora",
          description:
            "Wari Chora, a hidden place in Meghalaya's Garo Hills, offers a paradise for rafting through a narrow canyon. Discover its location, access, and accommodations for a memorable experience.",
          images: wari,
        },
      ],
      thingsToDo: [
        {
          name: "Visit the Waterfalls",
          description:
            "Rongrenggiri Falls and Pandalam Falls are nearby waterfalls in Garo Hills region, offering fresh water and breathtaking views.",
          image: rongren,
        },
        {
          name: "Indulge in Traditional Garo Cuisine",
          description:
            "Garo Hills offers a unique culinary experience with traditional Garo cuisine, such as Nakham Bitchi, Wak Pura, and Chigong Dare.",
          image: garo_cuisine,
        },
        {
          name: "Experience the Rural Lifestyle",
          description:
            "Experience the rural lifestyle of the local communities by engaging and understanding their customs and traditions.",
          image: garo_culture,
        },
        {
          name: "Visiting Fish Sanctuaries",
          description:
            "Nature lovers can go to Tura's Fish Sanctuaries to witness natural beauty and capture photos of diverse fish species. These sanctuaries also provide opportunities for fishing enthusiasts, with local experts guiding them in catching diverse fish species. Explore the diverse range of fish species at the fish sanctuaries, witnessing mind-blowing creatures up close and enjoying a serene environment.",
          image: jadesii,
        },
        {
          name: "Embark on Nature Walks",
          description:
            "Wari Chora offers nature walks through scenic landscapes, allowing visitors to explore various flora and fauna.",
          image: wari,
        },
      ],
    },
    {
      name: "South West Khasi Hills",
      description:
        "Explore the enchanting beauty of South West Khasi Hills, Meghalaya. This region offers breathtaking views of magnificent hills, lush green forests, and charming villages. It is a paradise for nature lovers and adventurous travelers seeking a getaway from city life.",
      image: chum_chum_falls,
      facts: [
        "South West Khasi Hills is a district known for its serene beauty and lush greenery.",
        "The region is home to sacred forests and natural pools with crystal-clear water.",
        "Mawkyrwat, the district headquarters, is a charming town with a laid-back atmosphere.",
        "The people of South West Khasi Hills primarily belong to the Khasi tribe.",
        "It offers opportunities for trekking and exploring offbeat trails in the Khasi Hills.",
      ],
      sublocations: [
        {
          name: "Wahrilang View Point",
          description:
            "Wahrilang View Point in South West Khasi Hills District, Meghalaya, offers breathtaking views of magnificent hills, lush green forests, and charming villages. Visitors can enjoy local delicacies and trek to the point, where rare birds and small mammals are common. This must-visit destination is perfect for nature lovers and adventurous travelers seeking a getaway from city life.",
          images: wahrilang_view_point,
        },
        {
          name: "Nongnah View Point",
          description:
            "Nongnah View Point is a breathtaking tourist destination in the southwest of Khasi Hills, offering stunning views of the natural mountains and unique tribal homes. Visitors can enjoy nature trails, treks, hiking, and interact with locals. The point offers a chance to learn about local culture and interact with residents, making it a must-visit destination for those seeking rural Meghalaya beauty and cultural experiences.",
          images: nongnah_view_point,
        },
        {
          name: "Mawtynhiang Caves",
          description:
            "Mawtynhiang Caves located in Khasi Hills, are popular among adventure seekers and nature lovers. The 100-meter-long cave is made of natural hot rock and features unusual plant organisms. Visitors must walk through dense forests and meadows, crossing deep rivers for an unforgettable experience. The cave's geological features, biodiversity, and breathtaking environment make it a highlight of any trip.",
          images: mawtynhiang_caves,
        },
        {
          name: "Chum Chum Falls",
          description:
            "Chum Chum Falls located in South West Khasi Hills Region, India, is a stunning tourist destination offering a relaxing experience. Surrounded by dense forest and mountains, this waterfall is a tributary of the Nongkhnum River. Visitors can enjoy activities like swimming, picnicking, camping, and bird watching, immersing themselves in aquatic scenic nature and rich culture. This popular destination is a must-see in Meghalaya for a memorable and unique experience.",
          images: chum_chum_falls,
        },
        {
          name: "Od Ringai Falls",
          description:
            "Od Ringai Falls located in South West Khasi, Meghalaya, offers breathtaking beauty and tranquility. Visitors can explore the waterfall through winding trails, observing various plants and animals, and capturing stunning visuals. This serene tourist destination is perfect for nature lovers and photographers.",
          images: od_ringai_falls,
        },
      ],
      thingsToDo: [
        {
          name: "Explore Nongkhnum Island",
          description:
            "Visit the largest river island in Meghalaya, Nongkhnum Island, located at the confluence of the Kynshi and Symper rivers. It offers beautiful landscapes, sandy beaches, and opportunities for camping and hiking.",
          image: Nongkhnum,
        },
        {
          name: "Explore Waterfalls",
          description:
            " The breathtaking waterfalls in South West Khasi Hills is placed near Pongtung village and is a famous spot amongst nature fanatics. Cascading waterfalls in the surrounding lush landscapes offer beautiful views. One of the well-known fall is Chum Chum Falls, close to Kharkutta village, gives cascading waters surrounded with the aid of a pristine forest, making it a famous destination for nature lovers.",
          image: Noh,
        },
        {
          name: "Experience Local Cuisine",
          description:
            "Indulge in the delicious and unique flavors of Khasi cuisine. Try traditional dishes like Jadoh (rice and meat), Dohneiiong (pork with black sesame), and Putharo (rice cake).",
          image: khasi_cuisine,
        },
        {
          name: "Explore Mawsynram",
          description:
            "Visit the wettest place on Earth, Mawsynram, known for its perpetual rainfall. Explore unique natural formations, including stalactite and stalagmite caves.",
          image: mawsy,
        },
      ],
    },
    {
      name: "Assam",
      description:
        "Assam, a northeastern state in India, is known for its breathtaking landscapes, diverse wildlife, colorful culture, and rich history. Here are some of the best things to do in Assam, making it a must-visit tourist destination.",
      image: Kaziranga,
      facts: [
        "Assam is a northeastern state of India known for its tea gardens and wildlife reserves.",
        "The Kaziranga National Park is a UNESCO World Heritage Site and home to one-horned rhinos.",
        "Majuli, the largest river island in the world, is located in the Brahmaputra River in Assam.",
        "The Kamakhya Temple in Guwahati is a revered pilgrimage site for devotees.",
        "Assam is famous for its traditional silk weaving, producing exquisite Muga and Pat silk.",
      ],
      sublocations: [
        {
          name: "Kaziranga National Park",
          description:
            "Visit the well-known Kaziranga National Park, a UNESCO World Heritage Site, to revel in the joys of seeing the majestic one-horned rhinoceros in its natural habitat. Take an interesting jeep or elephant safari to discover the park and notice different specific wildlife.",
          images: assam,
        },
        {
          name: "Kamakhya Temple",
          description:
            "Pay a go to to Kamakhya Temple, a renowned Shakti Peetha, located in Guwahati. This sacred temple attracts devotees from all around the country and offers a breathtaking view of the town.",
          images: kamakhya_temple,
        },
        {
          name: "Tea Gardens of Assam",
          description:
            "Take a leisurely walk through the plush green tea gardens of Assam, and learn about the problematic technique of tea manufacturing. Visit the tea estates of Dibrugarh or Jorhat to taste some of the finest Assam tea.",
          images: tea,
        },
        {
          name: "Manas National Park",
          description:
            "Embark on a fauna and flora adventure at Manas National Park, another UNESCO World Heritage Site in Assam. This park is home to several endangered species, including tigers, elephants, and golden langurs.",
          images: manas,
        },
        {
          name: "Assam State Zoo",
          description:
            "Visit the Assam State Zoo in Guwahati to witness an in-depth series of wildlife, such as various species of birds, mammals, and reptiles. Don't leave out the possibility to see the rare and endangered species housed inside the zoo.",
          images: assam_zoo,
        },
        {
          name: "River Brahmaputra",
          description:
            "Take a river cruise at the powerful Brahmaputra River and experience the scenic splendor of Assam's countryside. Witness beautiful sunsets and explore the river's islands and riverbank villages.",
          images: river_brahma,
        },
      ],
      thingsToDo: [
        {
          name: "Bihu Festival",
          description:
            "Experience the vibrancy and cultural richness of Assam via attending the Bihu Festival. This traditional and colorful festival celebrates the state's agricultural cycle and showcases energetic music and dance performances.",
          image: bihu,
        },
        {
          name: "Assamese Cuisine",
          description:
            "Indulge within the flavors of Assam through trying its precise cuisine. Enjoy traditional dishes like Assam tea, fish tenga (sour fish curry), khar, pitha (rice desserts), and numerous ethnic delicacies.",
          image: Assamese_Cuisine,
        },
        {
          name: "Visit the well-known Parks in Assam",
          description:
            "Explore Kaziranga National Park, a UNESCO World Heritage Site, to witness one-horned rhinoceros in their natural habitat. Also Explore Manas National Park, home to endangered species like tigers, elephants, and golden langurs, for a unique wildlife experience. These are the two famous tourist destination in Assam",
          image: Kaziranga,
        },
        {
          name: "Visit Famous Temples",
          description:
            ": Umananda Temple on Umananda Island, located near Guwahati, and Kamakhya Temple, a famous Shakti Peetha, for a tranquil escape from the city. Umananda Island, situated on the Brahmaputra River, is home to the Umananda Temple, dedicated to Lord Shiva",
          image: kamakhya_temple,
        },
        {
          name: "Tea Gardens of Assam",
          description:
            "Take a leisurely walk thru the plush inexperienced tea gardens of Assam, and learn about the problematic technique of tea manufacturing. Visit the tea estates of Dibrugarh or Jorhat to taste some of the finest Assam tea.",
          image: tea,
        },
      ],
    },
  ],
};

export const sellingPackages = {
  sellingPackages: [
    {
      title: "SHILLONG AND SOHRA TOUR",
      image: shillong_bg,
      description:
        "This package takes you to the capital city of Shillong famous for its pristine lakes, waterfalls, and pleasant weather. You will also visit Sohra, which is renowned as one of the wettest places on earth and is adorned with stunning landscapes, living root bridges, and beautiful waterfalls.",
    },
    {
      title: "MAWLYNNONG VILLAGE TOUR",
      image: krang_suri,
      description:
        "Mawlynnong, voted as the cleanest village in Asia, is a must-visit destination in Meghalaya. This package allows you to explore the unique living root bridges, lush gardens, and experience the warm hospitality of the locals.",
    },
    {
      title: "DAWKI AND UMNGOT RIVER TOUR",
      image: dawki1,
      description:
        "Dawki, situated on the border of India and Bangladesh, is famous for its crystal-clear Umngot River. This tour package offers a thrilling boat ride on the river, where you can witness the magical underwater view and enjoy the scenic beauty of the surrounding landscapes.",
    },
    {
      title: "NONGRIAT AND DOUBLE DECKER LIVING ROOT BRIDGE TREK",
      image: doubleBridge,
      description:
        "This tour package is perfect for adventure enthusiasts. It takes you to Nongriat village, where you can trek through the dense jungles and witness the architectural marvels of the Double Decker Living Root Bridge, an incredible example of bioengineering.",
    },
    {
      title: "MAWSYNRAM CAVES AND WATERFALLS TOUR",
      image: mawkdok_bridge5,
      description:
        "Mawsynram, known for receiving the highest rainfall in the world, is home to numerous stunning caves and waterfalls. This package allows you to explore the fascinating Mawjymbuin Cave, Krem Dam, and the majestic Mawlyngbna Waterfall.",
    },
    {
      title: "JAINTIA HILLS HERITAGE TOUR",
      image: jainitia,
      description:
        "Jaintia Hills is a lesser-known gem in Meghalaya, offering a glimpse into the rich cultural heritage of the Jaintia tribe. This package includes visits to ancient monoliths, historical sites, traditional weaving centers, and local markets.",
    },
    {
      title: "GARO HILLS WILDLIFE TOUR",
      image: Nongkhnum,
      description:
        "If you are a nature lover and wildlife enthusiast, this tour package is ideal for you. It takes you to Garo Hills, where you can explore Nokrek National Park, Siju Cave, and spot diverse fauna such as Asian elephants, red pandas, and clouded leopards.",
    },
    {
      title: "ASSAM TOUR",
      image: assam,
      description:
        "This Tour Package is designed for Meghalaya and Assam offer a perfect blend of natural beauty, cultural diversity, and historical significance.",
    },
  ],
  mainSectionDescription:
    "These are some of the best selling tour packages in Meghalaya that cater to different interests and preferences. Whether you are seeking adventure, cultural experiences, or natural beauty, Meghalaya has something to offer for every traveler.",
};

export const TourPackages = {
  tourPackages: [
    {
      packageName: "SHILLONG AND SOHRA TOUR",
      Title: "Shillong And Sohra Tour",
      location: "Meghalaya",
      image: shillong_bg,
      duration: "4 days",
      description:
        "The Shillong journey package offers a charming tour to the adorable city of Shillong, which is located some of the lush Meghalayan hills. Shillong, additionally known as the Scotland of the East, enchants tourists with its lovely surroundings, agreeable weather, and lively way of life. With this excursion package deal, you could discover Shillong's pinnacle points of interest, consisting of as the well-known Elephant Falls, the tranquil Ward's Lake, the beautiful Shillong Peak, and the bustling marketplaces wherein you could buy neighborhood specialties and handicrafts. The Shillong tour package ensures a memorable revel in so that it will enthrall you, whether you're a fan of nature, an adventurer, or a tradition vulture",
      itinerary: [
        {
          day: "Day 1",
          title: "Arrival in Shillong",
          description:
            "Arrive at Guwahati Airport or Railway Station. Transfer to Shillong, Enroute Umiam Lake on the way. Explore the local markets in the evening. Overnight stay at the hotel in Shillong.",
          image: [shillong1, shillong2],
        },
        {
          day: "Day 2",
          title: "Sightseeing in Shillong",
          description:
            "After breakfast, head out for a full day sightseeing tour in Shillong. Visit the popular attractions like Shillong Peak, Elephant Falls, Ward's Lake, and Don Bosco Museum. Return to the hotel in the evening. Overnight stay at the hotel in Shillong.",
          image: [shillong2, shillong6, shillong7, shillong9],
        },
        {
          day: "Day 3",
          title: "Excursion to Sohra",
          description:
            'After breakfast, depart for Sohra known as the "Land of Living Roots." Visit Nohkalikai Falls, Seven Sisters Falls, Mawsmai Cave, and Eco Park. Experience the lush green landscapes and the abundant rainfall in Sohra. Return to Shillong in the evening. Overnight stay at the hotel in Shillong.',
          image: [shillong11, shillong14, shillong16, shillong17],
        },
        {
          day: "Day 4",
          title: "Departure",
          description:
            "After breakfast, check-out from the hotel. Transfer to Guwahati Airport or Railway Station for onward journey. ",
          image: [shillong19, shillong20, shillong21],
        },
      ],
      inclusion: [
        "Accommodation: The Shillong tour package includes a comfortable stay in well-appointed hotels or resorts, providing a relaxing and enjoyable experience throughout your trip.",
        "Sightseeing: The package includes guided tours to all the major tourist attractions in Shillong, allowing you to explore the natural beauty and cultural heritage of the city.",
        "Transport: The package covers transportation to and from the airport or railway station, as well as convenient transfers to the different sightseeing spots in and around Shillong.",
        "Guide: You will have the assistance of an experienced guide who can provide you with valuable insights and information about the places you visit.",
      ],
      exclusion: [
        "Airfare: The package price does not include the cost of airfare or any additional expenses associated with reaching Shillong. You will need to arrange your own travel to the city.",
        "Personal Expenses: Any personal expenses such as shopping, additional meals, beverages, or activities outside the package itinerary are not included and will be at your own expense.",
        "Entry Fees: Entrance fees to monuments, parks, and other attractions are not included in the package. You may need to pay separate fees at each site, as per the applicable rates.",
        "Travel Insurance: The package does not include travel insurance coverage. It is advisable to have your own travel insurance to safeguard against any unforeseen circumstances.",
        "Please note that the inclusions and exclusions mentioned above may vary depending on the specific tour package and the travel agency you choose.",
      ],
    },
    {
      packageName: "MAWLYNNONG VILLAGE TOUR",
      Title: "Mawlynnong Village Tour",
      duration: "5 days",
      image: mawlynong,
      description:
        "Experience the serenity and charm of Mawlynnong Village with our exclusive tour package. Nestled in the breathtaking landscapes of Meghalaya, Mawlynnong is a quaint village known for its clean and green environment. With this package, you will get a chance to explore this mesmerizing destination and immerse yourself in its beauty.",
      itinerary: [
        {
          day: "Day 1",
          title:
            "Arrival in Guwahati - Visit Kamakhya Temple - Drive to Shillong",
          description:
            "Upon arrival in Guwahati, you will be greeted by our representative. Visit the Kamakhya Temple, a famous Hindu pilgrimage site dedicated to Goddess Kamakhya. Afterward, embark on a scenic drive to Shillong, the capital city of Meghalaya. Check-in at the hotel and relax for the night.",
          image: [kamakhya_temple, shillong1],
        },
        {
          day: "Day 2",
          title: "Explore Shillong and Drive to Sohra",
          description:
            'Start the day with a delicious breakfast at the hotel. Embark on a sightseeing tour of Shillong, including attractions like Umiam Lake, Elephant Falls, Shillong Peak, and Don Bosco Museum. Enjoy the beauty of nature as you drive to Sohra, known as the "wettest place on earth." Check-in at the hotel in Sohra and spend a comfortable night.',
          image: [shillong_bg, shillong6, shillong7, shillong9],
        },
        {
          day: "Day 3",
          title: "Explore Sohra",
          description:
            "After breakfast, set out to explore the magnificent attractions of Sohra. Visit popular sites like Nohkalikai Falls (the tallest plunge waterfall in India), Mawsmai Cave, Seven Sisters Falls, and Eco Park. Immerse yourself in the breathtaking landscapes and marvel at the natural wonders. Return to the hotel for a peaceful overnight stay.",
          image: [shillong11, shillong18, shillong14, shillong17],
        },
        {
          day: "Day 4",
          title: "Sohra to Mawlynnong and Dawki",
          description:
            'Begin the day with a delightful breakfast at the hotel. Depart for Mawlynnong village, often referred to as "Asia\'s Cleanest Village." Explore the picturesque village, known for its well-maintained cleanliness and fascinating living root bridges. Later, proceed to Dawki and visit the breathtaking Umngot River, renowned for its crystal clear waters. Enjoy boating on the river and soak in the scenic beauty surrounding you. Return to Sohra  and spend a comfortable night at the hotel.',
          image: [mawlynong1, mawlynong2, mawlynong3, mawlynong4],
        },
        {
          day: "Day 5",
          title: "Sightseeing in Shillong and Departure",
          description:
            "Enjoy a scrumptious breakfast at the hotel. Take a final opportunity to explore Shillong. Visit attractions like Ward's Lake, Lady Hydari Park, and Shillong Golf Course. In the evening, our representative will escort you to Guwahati for departure.",
          image: [shillong19, shillong20, shillong21],
        },
      ],
      inclusion: [
        "Accommodation: The Shillong tour package includes a comfortable stay in well-appointed hotels or resorts, providing a relaxing and enjoyable experience throughout your trip.",
        "Sightseeing: The package includes guided tours to all the major tourist attractions in Shillong, allowing you to explore the natural beauty and cultural heritage of the city.",
        "Transport: The package covers transportation to and from the airport or railway station, as well as convenient transfers to the different sightseeing spots in and around Shillong.",
        "Guide: You will have the assistance of an experienced guide who can provide you with valuable insights and information about the places you visit.",
      ],
      exclusion: [
        "Airfare: The package price does not include the cost of airfare or any additional expenses associated with reaching Shillong. You will need to arrange your own travel to the city.",
        "Personal Expenses: Any personal expenses such as shopping, additional meals, beverages, or activities outside the package itinerary are not included and will be at your own expense.",
        "Entry Fees: Entrance fees to monuments, parks, and other attractions are not included in the package. You may need to pay separate fees at each site, as per the applicable rates.",
        "Travel Insurance: The package does not include travel insurance coverage. It is advisable to have your own travel insurance to safeguard against any unforeseen circumstances.",
        "Please note that the inclusions and exclusions mentioned above may vary depending on the specific tour package and the travel agency you choose.",
      ],
    },
    {
      packageName: "DAWKI AND UMNGOT RIVER TOUR",
      Title: "Dawki And Umngot River Tour",
      duration: "6 days",
      image: dawki1,
      description:
        "Dawki and Umngot River Tour gives you the fine opportunity to enjoy the fascinating beauty of Dawki and its famous Umngot River. In this short day trip, you may move the stunning panorama of Meghalaya.You will see the emerald inexperienced waters of the Umngot River, that's famous for its distinct satisfactory. Sail in comfort and experience the tranquil scenery. Take stunning snap shots of a stunning placing and create memories in an effort to ultimate an entire life. The DAWKI AND UMNGOT RIVER TOUR package deal is ideal for human beings looking for a relaxing vacation and an opportunity to realize the natural beauty.     ",
      itinerary: [
        {
          day: "Day 1",
          title:
            "Arrive in Guwahati and proceed to visit Kamakhya Temple, a famous Hindu pilgrimage site.",
          description:
            "After temple visit, drive to Shillong. Check-in at the hotel and rest for the day.",
          image: [kamakhya_temple, shillong1],
        },
        {
          day: "Day 2",
          title:
            "Explore Shillong, visit popular attractions like Shillong Peak, Ward's Lake, and Don Bosco Museum",
          description:
            "In the afternoon, drive to Sohra (also known as Cherrapunjee). Check-in at the hotel in Sohra and relax.",
          image: [shillong7, donBosco, shillong9, shillong2],
        },
        {
          day: "Day 3",
          title: "Spend the day exploring Sohra",
          description:
            "Visit places like Nohkalikai Falls, Mawsmai Cave, and Seven Sisters Falls. Enjoy the panoramic views of the lush green valleys of Cherrapunjee.",
          image: [seven_sisters, shillong11, shillong16, shillong10],
        },
        {
          day: "Day 4",
          title:
            "After breakfast, proceed towards Mawlynnong, known as the cleanest village in Asia",
          description:
            "Enroute, visit the living root bridges of Riwai Village. Reach Mawlynnong and check-in at the homestay. Explore the village, witness the unique culture, and interact with the locals.",
          image: [mawlynong2, mawlynong3],
        },
        {
          day: "Day 5",
          title: "Explore Mawlynnong in the morning and then proceed to Dawki",
          description:
            "Visit the famous Umngot River, known for its crystal-clear waters and stunning green riverbed. Take a boat ride on the Umngot River and witness the breathtaking views of the river bed. For the adventurous souls, indulge in kayaking or cliff jumping activities. Return to Mawlynnong for an overnight stay.",
          image: [mawlynong1, mawlynong4, dawki1],
        },
        {
          day: "Day 7",
          title: "After breakfast, head back to Shillong",
          description:
            "Spend the day sightseeing in Shillong, visiting popular attractions like Elephant Falls and the Cathedral of Mary Help of Christians. In the evening, drop-off at the airport or railway station for departure. This simple and short itinerary covers the major highlights of the Dawki and Umngot River Tour, allowing you to experience the natural beauty and cultural richness of the region.",
          image: [shillong21, shillong20, shillong6],
        },
      ],
      inclusion: [
        "Accommodation: The Shillong tour package includes a comfortable stay in well-appointed hotels or resorts, providing a relaxing and enjoyable experience throughout your trip.",
        "Sightseeing: The package includes guided tours to all the major tourist attractions in Shillong, allowing you to explore the natural beauty and cultural heritage of the city.",
        "Transport: The package covers transportation to and from the airport or railway station, as well as convenient transfers to the different sightseeing spots in and around Shillong.",
        "Guide: You will have the assistance of an experienced guide who can provide you with valuable insights and information about the places you visit.",
      ],
      exclusion: [
        "Airfare: The package price does not include the cost of airfare or any additional expenses associated with reaching Shillong. You will need to arrange your own travel to the city.",
        "Personal Expenses: Any personal expenses such as shopping, additional meals, beverages, or activities outside the package itinerary are not included and will be at your own expense.",
        "Entry Fees: Entrance fees to monuments, parks, and other attractions are not included in the package. You may need to pay separate fees at each site, as per the applicable rates.",
        "Travel Insurance: The package does not include travel insurance coverage. It is advisable to have your own travel insurance to safeguard against any unforeseen circumstances.",
        "Please note that the inclusions and exclusions mentioned above may vary depending on the specific tour package and the travel agency you choose.",
      ],
    },
    {
      packageName: "NONGRIAT AND DOUBLE DECKER LIVING ROOT BRIDGE TREK",
      duration: "7 days",
      Title: "Nongriat And Double Decker Living Root Bridge Trek",
      description:
        "The Nongriat and Double Decker Living Root Bridge Trek itineraries are mainly treks for nature lovers and thrill seekers. Located inside the lovely country of Meghalaya, India, this trek takes you to the picturesque village of Nongriat and its specific two-story terrace for a stroll through dense forests and waterfalls. It is a pleasant experience as you stroll alongside that astonishing residential foundation. These bridges are a wonder of nature, shaped just like the roots of rubber trees, patiently studied by nearby people to construct robust and sturdy bridges over rivers. On this ride, you'll see the beautiful landscapes of Meghalaya in immaculate condition, where every step has superb perspectives. Nongriat and Double Decker Living Root Bridge Trek excursion bundle deals provide an unforgettable journey where you can disconnect from the hustle and bustle of city lifestyles and reconnect with nature. So, 100 percent go to your luggage and get the necessities for a ride of a lifetime in serene and mind-blowing Meghalaya.",
      image: nongriat,
      itinerary: [
        {
          day: "Day 1",
          title: "Itinerary for Dawki and Umngot River Tour Packages",
          description:
            "Arrive in Guwahati and proceed to visit the Kamakhya Temple. After temple visit, drive to Shillong. Check-in at the hotel and rest for the day.",
          image: [kamakhya_temple, shillong1],
        },
        {
          day: "Day 2",
          title:
            "Explore Shillong, visit popular attractions like Shillong Peak, Ward's Lake, and Don Bosco Museum",
          description:
            "In the afternoon, drive to Sohra (also known as Cherrapunjee). Check-in at the hotel in Sohra and relax.",
          image: [shillong7, donBosco, shillong9, shillong2],
        },
        {
          day: "Day 3",
          title: "Spend the day exploring Sohra",
          description:
            "Visit places like Nohkalikai Falls, Mawsmai Cave, and Seven Sisters Falls. Enjoy the panoramic views of the lush green valleys of Cherrapunjee.",
          image: [seven_sisters, shillong11, shillong16, shillong10],
        },
        {
          day: "Day 4",
          title:
            "After breakfast, proceed towards Mawlynnong, known as the cleanest village in Asia",
          description:
            "Enroute, visit the living root bridges of Riwai Village. Reach Mawlynnong and check-in at the homestay. Explore the village, witness the unique culture, and interact with the locals.",
          image: [mawlynong2, mawlynong3],
        },
        {
          day: "Day 5",
          title: "Explore Mawlynnong in the morning and then proceed to Dawki",
          description:
            "Visit the famous Umngot River, known for its crystal-clear waters and stunning green riverbed. Take a boat ride on the Umngot River and witness the breathtaking views of the river bed. For the adventurous souls, indulge in kayaking or cliff jumping activities. Return to Mawlynnong for an overnight stay.",
          image: [mawlynong1, mawlynong4, dawki1],
        },
        {
          day: "Day 6",
          title:
            "Begin the day by hiking to Nongriat, home to the famous double-decker living root bridges",
          description:
            "Trek down to the village and explore the beautiful bridges and the surrounding nature. Return to Mawlynnong in the evening and relax.",
          image: [nongriat1, nongriat2, nongriat3],
        },
        {
          day: "Day 7",
          title: "After breakfast, head back to Shillong",
          description:
            "Spend the day sightseeing in Shillong, visiting popular attractions like Elephant Falls and the Cathedral of Mary Help of Christians. In the evening, drop-off at the airport or railway station for departure. This simple and short itinerary covers the major highlights of the Dawki and Umngot River Tour, allowing you to experience the natural beauty and cultural richness of the region.",
          image: [shillong21, shillong20, shillong6],
        },
      ],
      inclusion: [
        "Accommodation: The Shillong tour package includes a comfortable stay in well-appointed hotels or resorts, providing a relaxing and enjoyable experience throughout your trip.",
        "Sightseeing: The package includes guided tours to all the major tourist attractions in Shillong, allowing you to explore the natural beauty and cultural heritage of the city.",
        "Transport: The package covers transportation to and from the airport or railway station, as well as convenient transfers to the different sightseeing spots in and around Shillong.",
        "Guide: You will have the assistance of an experienced guide who can provide you with valuable insights and information about the places you visit.",
      ],
      exclusion: [
        "Airfare: The package price does not include the cost of airfare or any additional expenses associated with reaching Shillong. You will need to arrange your own travel to the city.",
        "Personal Expenses: Any personal expenses such as shopping, additional meals, beverages, or activities outside the package itinerary are not included and will be at your own expense.",
        "Entry Fees: Entrance fees to monuments, parks, and other attractions are not included in the package. You may need to pay separate fees at each site, as per the applicable rates.",
        "Travel Insurance: The package does not include travel insurance coverage. It is advisable to have your own travel insurance to safeguard against any unforeseen circumstances.",
        "Please note that the inclusions and exclusions mentioned above may vary depending on the specific tour package and the travel agency you choose.",
      ],
    },
    {
      packageName: "MAWSYNRAM CAVES AND WATERFALLS TOUR",
      duration: "7 days",
      Title: "Mawsynram Caves and Falls Tour Package",
      description:
        "Experience the Stunning and Breathtaking Panorama of Mawsynram, referred to as the “Wettest Place on Earth” alongwith our Professional Guides. Marvel at Lovely Historical Caves and the Breathtaking Beauty of Mawsynram Waterfalls.",
      image: mawsy,
      itinerary: [
        {
          day: "Day 1",
          title: "Arrival in Guwahati and Transfer to Shillong",
          description:
            "Begin your journey by arriving in Guwahati and transferring to the beautiful city of Shillong, also known as the 'Scotland of the East'.",
          image: [kamakhya_temple, shillong1],
        },
        {
          day: "Day 2",
          title: "Sightseeing in Shillong and Sohra",
          description:
            "Explore the enchanting sights of Shillong, including the famous Elephant Falls, Shillong Peak, and the captivating Ward's Lake. In the afternoon, proceed to Sohra, commonly known as Sohra, famous for its stunning waterfalls and lush green landscapes.",
          image: [shillong2, shillong6, shillong7],
        },
        {
          day: "Day 3",
          title: "Explore Sohra",
          description:
            "Spend an entire day exploring the wonders of Sohra. Visit the mesmerizing Nohkalikai Falls, Seven Sisters Falls, and the living root bridges of Mawlynnong, an award-winning cleanest village in Asia.",
          image: [shillong11, seven_sisters, mawlynong1],
        },
        {
          day: "Day 4",
          title: "Sohra to Mawlynnong",
          description:
            "After breakfast, travel to Mawlynnong, a picturesque village known for its pristine natural beauty. Explore the village and experience the warm hospitality of the locals. Don't miss the opportunity to walk across the famous skywalk and enjoy panoramic views of the surrounding forests.",
          image: [mawlynong2, mawlynong3, mawlynong4],
        },
        {
          day: "Day 5",
          title: "Explore Dawki",
          description:
            "Embark on a trip to Dawki, a small town situated on the India-Bangladesh border. Explore the crystal clear waters of the Umngot River and indulge in boating and kayaking activities. Don't forget to capture the breathtaking views of the Dawki Bridge.",
          image: [nongriat1, dawki1],
        },
        {
          day: "Day 6",
          title: "Explore Nongriat",
          description:
            "Head to Nongriat, a village famous for its living root bridges. Take a trek through beautiful landscapes and dense forests to reach the Double Decker Living Root Bridge. Marvel at the unique architectural beauty of these bridges made from tree roots.",
          image: [doubleBridge, mawsynram1, mawsynram2, mawsynram3],
        },
        {
          day: "Day 7",
          title: "Explore Mawsynram",
          description:
            "Conclude your tour by visiting Mawsynram, known as one of the wettest places on Earth. Explore the captivating Mawsmai Caves, rock formations, and the enchanting natural beauty of the region. Experience the thrill of exploring caves and waterfalls and immerse yourself in the natural wonders of Mawsynram on this incredible tour package.",
          image: [shillong21, shillong20, shillong6],
        },
      ],
      inclusion: [
        "Accommodation: The Mawsynram tour package includes a comfortable stay in well-appointed hotels or resorts, providing a relaxing and enjoyable experience throughout your trip.",
        "Sightseeing: The package includes guided tours to all the major tourist attractions in Mawsynram, allowing you to explore the natural beauty and cultural heritage of the region.",
        "Transport: The package covers transportation to and from the airport or railway station, as well as convenient transfers to the different sightseeing spots in and around Mawsynram.",
        "Guide: You will have the assistance of an experienced guide who can provide you with valuable insights and information about the places you visit.",
      ],
      exclusion: [
        "Airfare: The package price does not include the cost of airfare or any additional expenses associated with reaching Mawsynram. You will need to arrange your own travel to the city.",
        "Personal Expenses: Any personal expenses such as shopping, additional meals, beverages, or activities outside the package itinerary are not included and will be at your own expense.",
        "Entry Fees: Entrance fees to monuments, parks, and other attractions are not included in the package. You may need to pay separate fees at each site, as per the applicable rates.",
        "Travel Insurance: The package does not include travel insurance coverage. It is advisable to have your own travel insurance to safeguard against any unforeseen circumstances.",
        "Please note that the inclusions and exclusions mentioned above may vary depending on the specific tour package and the travel agency you choose.",
      ],
    },
    {
      packageName: "ASSAM TOUR",
      duration: "5 days",
      Title: "ASSAM TOUR PACKAGES",
      description:
        "Assam is a state in northeastern India, south of the eastern Himalayas. It is known for its wildlife preserves, including the UNESCO World Heritage sites of Kaziranga National Park, home to the majority of the world's one-horned rhinos, and Manas National Park. The capital, Dispur, is a suburb of the larger city of Guwahati.Assam has a diverse population belonging to different ethnic and religious groups. The Assamese culture is a rich melting pot of various indigenous groups. Assamese cuisine is known for dishes like khar, tenga and pitika which use local ingredients like herbs, greens, fish and rice.",
      image: assam,
      itinerary: [
        {
          day: "Day 1",
          title: "Arrive at Guwahati and Transfer to Shillong",
          description:
            "Arrive at Guwahati airport and transfer to Shillong, Meghalaya's capital, to visit Barapani Lake. Enjoy picturesque views and water sports before checking in at a hotel and exploring local markets. Overnight stay in Shillong.",
          image: [kamakhya_temple, shillong1],
        },
        {
          day: "Day 2",
          title: "Explore Shillong and Visit Sohra (Cherrapunjee)",
          description:
            "Explore Shillong, then drive to Sohra to visit Nohkalikai Waterfall, Mawsmai Cave, and Seven Sisters Falls. Also Explore Eco Park, Living Root Bridges, and Elephants Falls before returning to Shillong. Overnight stay.",
          image: [shillong2, shillong6, shillong7, shillong9],
        },
        {
          day: "Day 3",
          title: "Explore Sohra and Visit Mawlynnong and Dawki",
          description:
            "Spend the day exploring Sohra. Visit Mawlynnong village, Asia's cleanest village, and Dawki, a border town with crystal-clear Umngot River waters. Enjoy the village's cleanliness and natural beauty, witnessing the famous living root bridge. Return to Shillong for leisurely exploration and overnight stay.",
          image: [chum_chum_falls, mawlynong1, mawlynong4, dawki1],
        },
        {
          day: "Day 4",
          title: "Visit Mawlynnong Village and Dawki",
          description:
            "After breakfast, proceed towards Mawlynnong, known as the cleanest village in Asia. Enroute, visit the living root bridges of Riwai Village. Reach Mawlynnong and check-in at the homestay. Explore the village, witness the unique culture, and interact with the locals.",
          image: [mawlynong, mawlynong2, mawlynong3],
        },
        {
          day: "Day 5",
          title: "Departure from Shillong",
          description:
            "Explore Mawlynnong in the morning and then proceed to Dawki. Shillong to Guwahati, visit Kamakhya Temple on Nilachal Hill, Umananda Temple on Peacock Island, and enjoy a sunset cruise on the Brahmaputra River. Overnight stay.",
          image: [shillong21, shillong20, shillong6],
        },
      ],
      inclusion: [
        "Accommodation: The Shillong tour package includes a comfortable stay in well-appointed hotels or resorts, providing a relaxing and enjoyable experience throughout your trip.",
        "Sightseeing: The package includes guided tours to all the major tourist attractions in Shillong, allowing you to explore the natural beauty and cultural heritage of the city.",
        "Transport: The package covers transportation to and from the airport or railway station, as well as convenient transfers to the different sightseeing spots in and around Shillong.",
        "Guide: You will have the assistance of an experienced guide who can provide you with valuable insights and information about the places you visit.",
      ],
      exclusion: [
        "Airfare: The package price does not include the cost of airfare or any additional expenses associated with reaching Shillong. You will need to arrange your own travel to the city.",
        "Personal Expenses: Any personal expenses such as shopping, additional meals, beverages, or activities outside the package itinerary are not included and will be at your own expense.",
        "Entry Fees: Entrance fees to monuments, parks, and other attractions are not included in the package. You may need to pay separate fees at each site, as per the applicable rates.",
        "Travel Insurance: The package does not include travel insurance coverage. It is advisable to have your own travel insurance to safeguard against any unforeseen circumstances.",
        "Please note that the inclusions and exclusions mentioned above may vary depending on the specific tour package and the travel agency you choose.",
      ],
    },
    {
      packageName: "GARO HILLS WILDLIFE TOUR",
      duration: "5 days",
      Title: "GARO HILLS WILDLIFE TOUR PACKAGE",
      description:
        "Plan your Exciting Journey through the wildlife wonder and Explore Exciting Adventure of Garo Hills that will leave you thirst for more. Wander through dense forests, encounter exotic wildlife species, immerse yourself in the breathtaking beauty of the natural paradise of Garo Hills in Meghalaya. Unleash the inner nomad and discover hidden treasures waiting to be discovered.",
      image: wah_kba,
      itinerary: [
        {
          day: "Day 1",
          title: "Arrive at Guwahati and Transfer to Shillong",
          description:
            "Arrival in Guwahati - Transfer to Tura Upon arrival at Guwahati Airport, you will be greeted by our representative who will assist you in your transfer to Tura, a picturesque town in the Garo Hills region. After reaching Tura, check-in to your hotel and spend the rest of the day at leisure, exploring the nearby surroundings.",
          image: [kamakhya_temple, shillong1],
        },
        {
          day: "Day 2",
          title: "Explore Shillong and Visit Sohra (Cherrapunjee)",
          description:
            "Explore Balpakram National Park Embark on an exciting full-day excursion to Balpakram National Park, one of the prominent wildlife destinations in Garo Hills. Enjoy a thrilling jeep safari in the park to witness various wildlife species such as elephants, golden langurs, Indian bison, and many more. Take in the mesmerizing views of the landscape adorned with deep gorges and rolling hills.",
          image: [garo1, garo2, garo3, garo4],
        },
        {
          day: "Day 3",
          title: "Explore Sohra and Visit Mawlynnong and Dawki",
          description:
            "Visit Siju Caves and Nokrek National Park Start your day with a visit to Siju Caves, a network of limestone caves known for their stalagmites and stalactites. Explore the magnificent underground formations and witness the unique biodiversity that thrives inside the caves. Afterward, proceed to Nokrek National Park, home to the endangered red panda and various species of birds. Take a nature walk and soak in the tranquil ambiance of the park.",
          image: [dawki1, siju, nonkred],
        },
        {
          day: "Day 4",
          title: "Visit Mawlynnong Village and Dawki",
          description:
            "Leisure day in Garo Hills On this day, you have the freedom to relax and explore the pristine beauty of Garo Hills at your own pace. You can visit local markets, interact with the friendly locals, or simply unwind amidst the natural surroundings. In the evening, you can enjoy a bonfire with traditional music and dance performances",
          image: [mawlynong, shillong_market, khasi_tradition],
        },
        {
          day: "Day 5",
          title: "Departure from Shillong",
          description:
            "Departure from Tura After breakfast, check out from your hotel in Tura and proceed towards Guwahati for your onward journey. Bid adieu to the charming Garo Hills as you take with you unforgettable memories of your wildlife adventure.",
          image: [shillong21, shillong20, shillong6],
        },
      ],
      inclusion: [
        "Accommodation: The Shillong tour package includes a comfortable stay in well-appointed hotels or resorts, providing a relaxing and enjoyable experience throughout your trip.",
        "Sightseeing: The package includes guided tours to all the major tourist attractions in Shillong, allowing you to explore the natural beauty and cultural heritage of the city.",
        "Transport: The package covers transportation to and from the airport or railway station, as well as convenient transfers to the different sightseeing spots in and around Shillong.",
        "Guide: You will have the assistance of an experienced guide who can provide you with valuable insights and information about the places you visit.",
      ],
      exclusion: [
        "Airfare: The package price does not include the cost of airfare or any additional expenses associated with reaching Shillong. You will need to arrange your own travel to the city.",
        "Personal Expenses: Any personal expenses such as shopping, additional meals, beverages, or activities outside the package itinerary are not included and will be at your own expense.",
        "Entry Fees: Entrance fees to monuments, parks, and other attractions are not included in the package. You may need to pay separate fees at each site, as per the applicable rates.",
        "Travel Insurance: The package does not include travel insurance coverage. It is advisable to have your own travel insurance to safeguard against any unforeseen circumstances.",
        "Please note that the inclusions and exclusions mentioned above may vary depending on the specific tour package and the travel agency you choose.",
      ],
    },
    {
      packageName: "JAINTIA HILLS HERITAGE TOUR",
      duration: "5 days",
      Title: "JAINTIA HILLS HERITAGE TOUR PACKAGE",
      description:
        "This tour is designed to get you acquainted with the fascinating Jaintia traditions and customs. Then, venture into the depths of the Jaintia Caves, exploring the mysterious chambers that offer a breathtaking adventure among the fascinating rocks and subterranean rivers, and the practical features of nature’s masterpiece.",
      image: jainitia,
      itinerary: [
        {
          day: "Day 1",
          title: "Arrive at Guwahati and Transfer to Shillong",
          description:
            " Begin your by arriving in Guwahati, the gateway to Northeast India. From there, embark on a scenic drive to Shillong, the capital of Meghalaya. Check into your hotel and spend the evening exploring the vibrant markets and local cuisine of Shillong.",
          image: [kamakhya_temple, shillong1],
        },
        {
          day: "Day 2",
          title: "SIGHTSEEING IN SHILLONG - SOHRA",
          description:
            " After breakfast, set out for a full day of sightseeing in Shillong, also known as the 'Scotland of the East'. Visit popular attractions like the Shillong Peak, Elephant Falls, and the Don Bosco Museum. In the afternoon, continue your journey to the enchanting town of Sohra, famous for its breathtaking waterfalls and living root bridges. Check into your hotel and enjoy a relaxing evening. ",
          image: [shillong2, shillong6, shillong7, shillong9],
        },
        {
          day: "Day 3",
          title: "EXPLORE SOHRA",
          description:
            " After breakfast, explore the natural wonders of Sohra. Visit attractions like Nohkalikai Falls, renowned as the tallest plunge waterfall in India, and the Seven Sisters Falls, a magnificent cascade formed by seven distinct streams. Additionally, explore the Mawsmai Caves, which are natural limestone formations known for their impressive stalagmites and stalactites.",
          image: [seven_sisters, shillong11, shillong16, shillong10],
        },
        {
          day: "Day 4",
          title: "SOHRA – MAWLYNNONG - DAWKI ",
          description:
            "Today, embark on a scenic drive from Sohra to Mawlynnong, a picturesque village known as the cleanest village in Asia. Marvel at the pristine beauty of this village, adorned with beautiful gardens and bamboo bridges. Afterward, proceed to Dawki, a small town famous for its crystal-clear Umngot River and the breathtaking Dawki Bridge. Enjoy a boat ride on the Umngot River and immerse yourself in the surreal beauty of the surroundings.           .",
          image: [mawlynong, dawki1],
        },
        {
          day: "Day 5",
          title: "EXPLORE JAINTIA HILLS ",
          description:
            " Set out on an adventure to explore the lesser-known Jaintia Hills. Visit the enchanting Krang Suri Falls, surrounded by lush greenery and turquoise pools. Explore the sacred Siddheswar Temple and enjoy the scenic beauty of the Jaintia Hills before returning to your hotel in Sohra for the night",
          image: [jainitia, jainitia1, jainitia2],
        },
        {
          day: "Day 6",
          title: "EXPLORE NONGRIAT AND MAWSYNRAM ",
          description:
            "Today, embark on a thrilling trek to Nongriat village, famous for its double-decker living root bridges. Trek through dense forests, cross hanging bridges, and reach the mesmerizing waterfalls in this remote village. Afterward, visit Mawsynram, the wettest place on earth, known for its unique landscapes and abundant rainfall. Explore the fascinating caves and waterfalls in Mawsynram before returning to Shillong.",
          image: [mawsy, doubleBridge, nongriat1, nongriat2],
        },
        {
          day: "Day 7",
          title: "GUWAHATI DEPARTURE",
          description:
            " After breakfast, bid farewell to the scenic beauty of Meghalaya and proceed.",
          image: [shillong19, shillong20, shillong21],
        },
      ],
      inclusion: [
        "Accommodation: This tour package includes a comfortable stay in well-appointed hotels or resorts, providing a relaxing and enjoyable experience throughout your trip.",
        "Sightseeing: The package includes guided tours to all the major tourist attractions in Shillong, allowing you to explore the natural beauty and cultural heritage of the city.",
        "Transport: The package covers transportation to and from the airport or railway station, as well as convenient transfers to the different sightseeing spots in and around Shillong.",
        "Guide: You will have the assistance of an experienced guide who can provide you with valuable insights and information about the places you visit.",
      ],
      exclusion: [
        "Airfare: The package price does not include the cost of airfare or any additional expenses associated with reaching Shillong. You will need to arrange your own travel to the city.",
        "Personal Expenses: Any personal expenses such as shopping, additional meals, beverages, or activities outside the package itinerary are not included and will be at your own expense.",
        "Entry Fees: Entrance fees to monuments, parks, and other attractions are not included in the package. You may need to pay separate fees at each site, as per the applicable rates.",
        "Travel Insurance: The package does not include travel insurance coverage. It is advisable to have your own travel insurance to safeguard against any unforeseen circumstances.",
        "Please note that the inclusions and exclusions mentioned above may vary depending on the specific tour package and the travel agency you choose.",
      ],
    },
    {
      packageName: "COUPLE TRIP",
      duration: "7 days",
      Title: "COUPLE TRIP PACKAGE",
      description:
        "Indulge in the perfect romantic escapade with our tailor-made couples trip that promises to ignite sparks and create unforgettable memories. Picture yourselves in a world where time slows down, allowing you to savor each cherished moment together. Our exquisite destination offers a haven of tranquility, where love takes center stage against a backdrop of breathtaking vistas.Immerse yourselves in the lap of luxury as you stay in our handpicked accommodations, where every detail has been meticulously designed to enhance your connection. Whether it's a cozy cottage nestled amidst lush landscapes or a sophisticated beachfront villa with stunning sunsets as your daily spectacle, your abode will be a haven of comfort and intimacy.",
      image: couple_trip,
      itinerary: [
        {
          day: "Day 1",
          title: "Arrive at Shillong",
          description:
            "Arrive at Shillong Airport/Railway Station, meet agent, enjoy a leisurely day exploring local markets, and stay overnight.",
          image: [kamakhya_temple, shillong1],
        },
        {
          day: "Day 2",
          title: "Explore Shillong",
          description:
            "Explore Shillong, visit Elephant Falls, Don Bosco Museum, Shillong Peak, Lady Hydari Park, and enjoy local cafes and nightlife before staying overnight.",
          image: [shillong11, shillong18, shillong14, shillong17],
        },
        {
          day: "Day 3",
          title: "Sohra Excursion",
          description:
            "Sohra Excursion: Explore the wettest place, Nohkalikai Falls, Mawsmai Cave, Seven Sisters Falls, and Living Root Bridges, enjoy mist-covered hills, lush green landscapes, and return to Shillong.",
          image: [mawsy, doubleBridge, nongriat1, nongriat2],
        },
        {
          day: "Day 4",
          title: "Transfer to Mawlynnong",
          description:
            "Transfer to Mawlynnong, the cleanest village in Asia, for a picturesque tour, visiting the living root bridge, and enjoying a romantic Sky View Ropeway.",
          image: [chum_chum_falls, mawlynong1, mawlynong4, dawki1],
        },
        {
          day: "Day 5",
          title: "Dawki and Shnongpdeng Excursion",
          description:
            "Dawki and Shnongpdeng Excursion: Enjoy boat rides on the Umngot River, visit Shnongpdeng campsite, and engage in activities like kayaking, snorkeling, and river tubing before returning to Mawlynnong.",
          image: [mawlynong, dawki1],
        },
        {
          day: "Day 6",
          title: "Explore Jaintia Hills",
          description:
            "Transfer to Jaintia Hills, visit Krang Suri Waterfall, enjoy a serene atmosphere, and enjoy the beautiful surroundings before staying overnight.",
          image: [jainitia, jainitia1, jainitia2],
        },
        {
          day: "Day 7",
          title: "Departure",
          description: "Depart for Shillong Airport/Railway station",
          image: [shillong19, shillong20, shillong21],
        },
      ],
      inclusion: [
        "Accommodation: Comfortable stay in well-appointed hotels or resorts.",
        "Sightseeing: Guided tours to major tourist attractions.",
        "Transport: Transportation to and from airport/railway station and sightseeing spots.",
        "Guide: Assistance of an experienced guide.",
      ],
      exclusion: [
        "Airfare: The package price does not include airfare or any additional expenses associated with reaching Shillong.",
        "Personal Expenses: Any personal expenses such as shopping, additional meals, beverages, or activities outside the package itinerary are not included.",
        "Entry Fees: Entrance fees to monuments, parks, and other attractions are not included.",
        "Travel Insurance: The package does not include travel insurance coverage.",
      ],
    },
    {
      packageName: "FAMILY TRIP",
      duration: "4 days",
      Title: "FAMILY TRIP PACKAGE",
      description:
        "Pack your bags, gather your loved ones, and get ready for a journey filled with laughter, bonding, and cherished memories. Our exclusive family trip is designed to whisk you away from the everyday hustle and bustle, and into a world of togetherness and exploration. Picture yourself basking in the beauty of nature's wonders, as you traverse through stunning landscapes hand in hand with your family. Whether it's building sandcastles on sun-kissed beaches, or venturing into lush forests teeming with life, each moment promises to be a treasure trove of joy. Our carefully crafted itinerary balances adventure and relaxation, ensuring there's something for every family member. Unleash your inner thrill-seeker with exciting activities that spark adrenaline, or simply unwind and bond during serene moments of tranquility.",
      image: family_trip,
      itinerary: [
        {
          day: "Day 1",
          title: "Arrive at Shillong",
          description:
            "Arrive at Guwahati Airport and drive to Shillong, Meghalaya's capital city. Visit the Don Bosco Centre for Indigenous Cultures, explore Ward's Lake, and enjoy boating. Explore Police Bazaar, a vibrant commercial hub, and enjoy local delicacies before returning to the hotel for a comfortable overnight stay.",
          image: [kamakhya_temple, shillong1],
        },
        {
          day: "Day 2",
          title: "Explore Sohra",
          description:
            "Visit Sohra, the wettest place on Earth, and explore the Nohkalikai Falls, Mawsmai Caves, Seven Sisters Falls, and Mawkdok Dympep Valley Viewpoint. Enjoy the breathtaking views and eco-friendly Mawkdok Dympep Valley Viewpoint. Return to Shillong and stay overnight.",
          image: [seven_sisters, shillong11, shillong16, shillong10],
        },
        {
          day: "Day 3",
          title: "Dawki and Mawlynnong Excursion",
          description:
            "Visit Dawki and experience the Umngot River's crystal-clear waters, then visit Mawlynnong Village, known for its cleanest village in Asia. Explore unique living root bridges and visit the Skywalk viewpoint for stunning views. Return to Shillong for overnight stay.",
          image: [mawlynong, dawki1],
        },
        {
          day: "Day 4",
          title: "Shillong Sightseeing",
          description:
            "Visit Shillong Peak, the highest point in Shillong, and explore Elephant Falls, a popular tourist spot. Visit the Cathedral of Mary Help of Christians and Lady Hydari Park before departing to Guwahati Airport.",
          image: [shillong19, shillong20, shillong21],
        },
      ],
      inclusion: [
        "Accommodation: Comfortable stay in well-appointed hotels or resorts.",
        "Sightseeing: Guided tours to major tourist attractions.",
        "Transport: Transportation to and from airport/railway station and sightseeing spots.",
        "Guide: Assistance of an experienced guide.",
      ],
      exclusion: [
        "Airfare: The package price does not include airfare or any additional expenses associated with reaching Shillong.",
        "Personal Expenses: Any personal expenses such as shopping, additional meals, beverages, or activities outside the package itinerary are not included.",
        "Entry Fees: Entrance fees to monuments, parks, and other attractions are not included.",
        "Travel Insurance: The package does not include travel insurance coverage.",
      ],
    },
    {
      packageName: "FRIENDS TRIP",
      duration: "5 days",
      Title: "FRIENDS TRIP PACKAGE",
      description:
        "Picture yourself strolling along pristine beaches, sharing stories around a bonfire under a starlit sky, and exploring hidden gems that only true adventurers discover. Our Friends Trip is more than just a vacation; it's a celebration of friendship, a fusion of exploration and relaxation that promises to rejuvenate your spirit.",
      image: friends_trip,
      itinerary: [
        {
          day: "Day 1",
          title: "Arrive at Shillong",
          description:
            "Embark on an unforgettable journey with your closest companions and make memories that will last a lifetime. Our Friends Trip experience is designed to whisk you away from the ordinary and immerse you in a world of laughter, adventure, and camaraderie.Savor the joy of discovering hidden gems hand in hand with your friends, as you traverse through breathtaking landscapes and capture candid moments that reflect the true essence of friendship. With carefully curated activities and accommodations, we ensure that every aspect of your journey is infused with comfort and delight.Join us for a Friends Trip that promises laughter, bonding, and the sheer pleasure of being in the company of those who understand you best. Let the adventure begin!",
          image: [kamakhya_temple, shillong1],
        },
        {
          day: "Day 2",
          title: "Explore Shillong",
          description:
            "Visit Shillong's famous attractions, including Umiam Lake, Don Bosco Museum, and Laitlum Grand Canyon, to explore North East India's rich cultural heritage. Enjoy a comfortable overnight stay before returning to your hotel.",
          image: [shillong11, shillong18, shillong14, shillong17],
        },
        {
          day: "Day 3",
          title: "Explore Sohra",
          description:
            "Explore Sohra, renowned for its wettest places, including Seven Sisters Falls, Nohkalikai Falls, and Mawsmai Cave. Enjoy the Eco Park and return to Shillong for an overnight stay.",
          image: [seven_sisters, shillong11, shillong16, shillong10],
        },
        {
          day: "Day 4",
          title: "Trek to Double Decker Living Root Bridge",
          description:
            "Enjoy an early breakfast and trek to Sohra's Double Decker Living Root Bridge, a natural wonder created by Khasi tribes using Ficus elastica tree roots. Return to Shillong and relax before an overnight stay.",
          image: [mawsy, doubleBridge, nongriat1, nongriat2],
        },
        {
          day: "Day 5",
          title: "Departure",
          description:
            "Bid farewell to Meghalaya, and transfer to Guwahati airport for Meghalaya adventure.",
          image: [shillong19, shillong20, shillong21],
        },
      ],
      inclusion: [
        "Accommodation: Comfortable stay in well-appointed hotels or resorts.",
        "Sightseeing: Guided tours to major tourist attractions.",
        "Transport: Transportation to and from airport/railway station and sightseeing spots.",
        "Guide: Assistance of an experienced guide.",
      ],
      exclusion: [
        "Airfare: The package price does not include airfare or any additional expenses associated with reaching Shillong.",
        "Personal Expenses: Any personal expenses such as shopping, additional meals, beverages, or activities outside the package itinerary are not included.",
        "Entry Fees: Entrance fees to monuments, parks, and other attractions are not included.",
        "Travel Insurance: The package does not include travel insurance coverage.",
      ],
    },
    {
      packageName: "BUSINESS TRIP",
      duration: "3 days, 2 nights",
      Title: "BUSINESS TRIP PACKAGE",
      description:
        "Our meticulously curated business trips are designed to elevate your corporate experience. Imagine stepping into a realm where boardroom meetings merge seamlessly with cultural immersions. Whether you're a trailblazing entrepreneur, a corporate powerhouse, or a visionary leader, our business trips are tailored to meet your objectives and aspirations.",
      image: business_trip,
      itinerary: [
        {
          day: "Day 1",
          title: "Arrive at Shillong",
          description:
            "Arrive at Guwahati Airport, transfer to Shillong, visit Umiam Lake, relax, explore local markets, and enjoy dinner and an overnight stay.",
          image: [kamakhya_temple, shillong1],
        },
        {
          day: "Day 2",
          title: "Explore Sohra",
          description:
            "Explore Sohra, the wettest place on Earth, including Mawsmai Cave and Sisters Falls, and engage in corporate meetings.",
          image: [shillong11, shillong18, shillong14, shillong17],
        },
        {
          day: "Day 3",
          title: "Shillong Sightseeing",
          description:
            "Visit Shillong Peak for panoramic views, explore Don Bosco Museum, and explore charming streets with vibrant cafes and traditional food stalls before departing from Guwahati Airport/Railway Station.",
          image: [shillong19, shillong20, shillong21],
        },
      ],
      inclusion: [
        "Accommodation: Comfortable stay in well-appointed hotels or resorts.",
        "Sightseeing: Guided tours to major tourist attractions.",
        "Transport: Transportation to and from airport/railway station and sightseeing spots.",
        "Guide: Assistance of an experienced guide.",
      ],
      exclusion: [
        "Airfare: The package price does not include airfare or any additional expenses associated with reaching Shillong.",
        "Personal Expenses: Any personal expenses such as shopping, additional meals, beverages, or activities outside the package itinerary are not included.",
        "Entry Fees: Entrance fees to monuments, parks, and other attractions are not included.",
        "Travel Insurance: The package does not include travel insurance coverage.",
      ],
    },
  ],
};
